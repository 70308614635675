import { Project } from "../../../../app/types";

export const mapProjectIdToProjectName = (
  projectId: number | null,
  projects: Project[]
): string | null | undefined => {
  if (projectId !== null) {
    const projectName = projects.find(
      (project) => project.value === projectId
    )?.label;
    return projectName;
  }
  return null;
};
