//früher UsersTime
export interface UsersDuration {
  userId: number;
  dur: number;
  username: string;
}

// Shortcuts XXX-000:
export const customerShortcutRegex = new RegExp(/^[A-Z]{2,}-[0-9]+\s?:/);

//früher RowType
export interface ReportRow {
  description: string;
  discountTime: number;
  totalUsersTime: number;
  jiraUrl: string | undefined;
  togglUrl: string;
  usersTime: UsersDuration[];
}

export interface Section {
  sectionType: string;
  isError: boolean;
  rowsData: ReportRow[];
  totalTime: number;
}

export enum Status {
  warning = "warning",
  error = "error",
  success = "success",
}

export type Shortcut = {
  shortcut: string;
  shortcutCount: number;
  shortcutStatus: Status;
};

export interface Report {
  sections: Section[];
  totalTime: number;
  totalDiscount: number;
  totalTimeWithoutDiscount: number;
  title: string;
  customerShortcuts: Shortcut[];
}
