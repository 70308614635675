import { TogglTimeEntriesArray } from "./index";

export const convertToTogglTimeEntries = (
  timeEntries: TogglTimeEntriesArray
): TogglTimeEntriesArray => {
  return timeEntries.map((timeEntry: any) => ({
    description: timeEntry.description,
    dur: timeEntry.dur,
    tags: timeEntry.tags,
    uid: timeEntry.uid,
    username: timeEntry.user,
    pid: timeEntry.pid,
  }));
};
