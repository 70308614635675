import { grey, lightBlue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { PaletteColor } from "@mui/material/styles/createPalette";
import { PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    table: TableColors;
    error: PaletteColor;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    table?: Partial<TableColors>;
    error?: PaletteColorOptions;
  }

  interface TableColors {
    border?: string;
    background?: string;
    bgColorRow?: string;
    backgroundHighlight?: string;
    backgroundRow?: string;
    backgroundDarker?: string;
    textHighlight?: string;
    textHeader?: string;
    textLight?: string;
    textDark: string;
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ["Roboto,Arial"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 100 },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#0288d1",
      dark: "#01579b",
      contrastText: "#fff",
    },

    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    neutral: {
      main: grey[500],
      light: grey[100],
      contrastText: "#fff",
    },
    table: {
      border: grey[200],
      background: "transparant",
      backgroundRow: "transparent",
      backgroundHighlight: "transparent",
      backgroundDarker: grey[100],
      textDark: "#000000",
      textHighlight: lightBlue[800], //discount
      textHeader: "#000000", // section
      textLight: grey[500],
    },
    error: {
      light: "#ffcbc4",
      main: "#ff7961",
    },
  },
});
