import * as React from "react";
import { Report } from "./types";
import { styled } from "@mui/material";
import { TotalResults } from "./TotalResults";
import { Sections } from "./Sections";
import { CustomerShortcuts } from "./CustomerShortcuts";

const StyledReportTitle = styled("h1")({
  backgroundColor: "transparent",
  marginBottom: 25,
  width: "100%",
  display: "block",
  fontFamily: "Roboto",
  padding: "10px",

  fontSize: "23px",
});

const StyledContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: 50,
});
const StyledReportRoot = styled("div")(({ theme }) => ({
  width: "100%",
  "& table": {
    fontFamily: "Roboto",
    backgroundColor: `${theme.palette.table.background}`,
  },

  "& td,& th": {
    border: "none",
    fontSize: "15px",
  },
  "& th": {
    backgroundColor: `${theme.palette.table.backgroundRow}`,
  },
}));

const StyledTotalResultsContainer = styled("div")({
  display: "flex",
  justifyContent: "right",
});
type Props = {
  report: Report;
  isCustomerViewChecked: boolean;
};

export const ReportTable = ({ report, isCustomerViewChecked }: Props) => {
  return (
    <StyledContainer>
      <StyledReportRoot>
        <StyledReportTitle>{report.title}</StyledReportTitle>
        <CustomerShortcuts customerShortcuts={report.customerShortcuts} />

        <Sections
          sections={report.sections}
          isCustomerViewChecked={isCustomerViewChecked}
        />
        {report.sections.length !== 0 && (
          <StyledTotalResultsContainer>
            <TotalResults
              totalTime={report.totalTime}
              totalDiscount={report.totalDiscount}
              totalTimeWithoutDiscount={report.totalTimeWithoutDiscount}
              isCustomerViewChecked={isCustomerViewChecked}
            />
          </StyledTotalResultsContainer>
        )}
      </StyledReportRoot>
    </StyledContainer>
  );
};
