export function removeDuplicates<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function groupBy<T, K extends string | number | symbol>(
  array: T[],
  iteratee: (item: T) => K
): Record<K, T[]> {
  return array.reduce((prev, acc) => {
    const key = iteratee(acc);

    if (key in prev) {
      prev[key].push(acc);
    } else {
      prev[key] = [acc];
    }
    return prev;
  }, {} as Record<K, T[]>);
}
