import { styled } from "@mui/material";

const StyledLink = styled("a")({
  color: "black",
  "@media print": {
    textDecoration: "none",
  },
});

interface Props {
  jiraUrl: string | undefined;
  description: string;
}

export const ReportTableTitle = (props: Props) => {
  if (props.jiraUrl !== undefined) {
    return (
      <>
        <StyledLink href={props.jiraUrl} target={"_blank"}>
          {props.description}
        </StyledLink>
      </>
    );
  } else {
    return <>{props.description}</>;
  }
};
