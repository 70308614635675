import { getSettingsFromLocalStorage } from "../../app/localStorage";
import { TogglTimeEntriesArray, Workspace } from "./index";
import { convertDateToStringWithoutTime } from "../../utils";
import { convertToTogglTimeEntries } from "./converterForTimeEntries";
import { Project } from "../../app/types";

interface User {
  userId: number;
  username: string;
}

const fetchAny = async (
  url: string,
  apiToken?: string | null
  // any is okay because we get Data from  API
): Promise<any> => {
  let response: Response;

  const apiTokenFromLocalStorage = getSettingsFromLocalStorage()?.inputApiToken;
  if (apiToken || apiTokenFromLocalStorage) {
    try {
      response = await fetch(url, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              // string to binary
              // https://developer.mozilla.org/en-US/docs/Glossary/Base64
              `${
                apiToken
                  ? apiToken
                  : getSettingsFromLocalStorage()?.inputApiToken
              }:api_token`
            ),
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      throw new Error("no connection");
    }

    if (response.ok) {
      return response.json();
    } else {
      if (response.status === 403) {
        throw new Error("Api Error 403");
      } else {
        throw new Error("unknown api error");
      }
    }
  } else {
    throw new Error("no Api-Token available");
  }
};

export const fetchWorkspaces = async (
  apiToken: string
): Promise<Workspace[]> => {
  const responseWorkspaces = await fetchAny(
    "https://api.track.toggl.com/api/v9/workspaces",
    apiToken
  );
  const workspaces = responseWorkspaces.map((workspace: any) => ({
    label: workspace.name,
    value: workspace.id,
  }));
  return workspaces;
};

export const fetchUsers = async (): Promise<User[]> => {
  const selectedWorkspaceId =
    getSettingsFromLocalStorage()?.selectedWorkspaceId;

  const responseUsers = await fetchAny(
    `https://api.track.toggl.com/api/v9/workspaces/${selectedWorkspaceId}/users`
  );

  const users = responseUsers.map((user: any) => {
    return {
      userId: user.id,
      username: user.fullname,
    };
  });
  return users;
};

export const fetchProjects = async (): Promise<Project[]> => {
  const selectedWorkspaceId =
    getSettingsFromLocalStorage()?.selectedWorkspaceId;

  const responseProjects = await fetchAny(
    `https://api.track.toggl.com/api/v9/workspaces/${selectedWorkspaceId}/projects`
  );

  const projects = responseProjects.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  return projects;
};

export const fetchTimeEntries = async (
  start: Date,
  end: Date,
  projectId: number | null
): Promise<TogglTimeEntriesArray> => {
  // get first page with Entries
  let timeEntryData = await fetchTimeEntriesPerPage(start, end, projectId, 1);

  //count Pages
  const numberOfTimeEntryPages = Math.ceil(
    timeEntryData.total_count / timeEntryData.per_page
  );

  // all time entries
  let timeEntries = timeEntryData.data;

  for (let page = 2; page <= numberOfTimeEntryPages; page++) {
    const timeEntryPage = await fetchTimeEntriesPerPage(
      start,
      end,
      projectId,
      page
    );

    timeEntries = [...timeEntries, ...timeEntryPage.data];
  }

  //convert to TogglTimeEntries Type
  timeEntries = convertToTogglTimeEntries(timeEntries);

  return timeEntries;
};

export const fetchTimeEntriesPerPage = async (
  start: Date,
  end: Date,
  projectId: number | null,
  page: number
): Promise<any> => {
  const settings = getSettingsFromLocalStorage();

  // info use URLSearchParams https://developer.mozilla.org/de/docs/Web/API/URLSearchParams
  const url = "https://api.track.toggl.com/reports/api/v2/details?";
  let singleSearchParams = new URLSearchParams({
    page: page.toString(),
    workspace_id: `${settings?.selectedWorkspaceId}`,
    since: convertDateToStringWithoutTime(start),
    until: convertDateToStringWithoutTime(end),
    user_agent: "toggl-report",
    project_ids: `${projectId}`,
  });

  let timeEntryPage = await fetchAny(
    url + singleSearchParams.toString(),
    settings?.inputApiToken
  );

  return timeEntryPage;
};

export const fetchTimeEntriesWithoutProjectId = async (
  start: Date,
  end: Date
): Promise<TogglTimeEntriesArray> => {
  let timeEntriesWithoutProjects = await fetchTimeEntries(start, end, 0);

  return timeEntriesWithoutProjects;
};
