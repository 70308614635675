import { TagSection } from "./TagSection";
import { Section } from "./types";

interface Props {
  sections: Section[];
  isCustomerViewChecked: boolean;
}

export const Sections = ({ sections, isCustomerViewChecked }: Props) => {
  return (
    <>
      {sections.map((section: Section) => {
        return (
          <TagSection
            key={section.sectionType}
            section={section}
            isCustomerViewChecked={isCustomerViewChecked}
            status={section.isError}
          />
        );
      })}
    </>
  );
};
