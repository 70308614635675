import { useSearchParamsNonOverwriting } from "../../../utils";
import { SetStateAction, useMemo } from "react";
import {
  convertHourRateFactorsToSearchParams,
  convertHourRateFromUrl,
} from "./converter";
import { HourRateFactor } from "../../../app/types";

export const useHourRateFactors = (): [
  HourRateFactor,
  (setHourRateFactors: SetStateAction<HourRateFactor>) => void
] => {
  // save the hourRates into the SearchParams
  const [urlSearchParams, saveSearchParamsInUrl] =
    useSearchParamsNonOverwriting();
  // wir woillen immer dass hourratefactor sich ändert wenn urlsearchparam sich ändert.
  const hourRateFactors = useMemo(
    () => convertHourRateFromUrl(urlSearchParams) ?? {},
    [urlSearchParams]
  );

  const setHourRateFactors = (
    newHourRateFactors: SetStateAction<HourRateFactor>
  ) => {
    if (hourRateFactors !== null) {
      const filter =
        typeof newHourRateFactors === "function"
          ? newHourRateFactors(hourRateFactors)
          : newHourRateFactors;

      saveSearchParamsInUrl(convertHourRateFactorsToSearchParams(filter));
    }
  };

  return [hourRateFactors, setHourRateFactors];
};
