import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import { Input } from "../Input";

import React from "react";
import { User } from "../../../app/types";

interface Props {
  hourRateFactor: number;
  onHourRateFactorChange: (hourRateFactor: number) => void;
  user: User;
}

export const HourRateRow = ({
  hourRateFactor,
  user,
  onHourRateFactorChange,
}: Props) => {
  const checkInput = (input: string): "warning" | "success" => {
    const inputAsNumber = parseFloat(input);
    if (
      !Number.isNaN(inputAsNumber) &&
      inputAsNumber <= 9999 &&
      inputAsNumber >= 0
    ) {
      return "success";
    }
    return "warning";
  };

  return (
    <TableRow key={user.username}>
      <TableCell>{user.username}</TableCell>
      <TableCell>
        <Input
          onFocus={(event) => {
            event.target.select();
          }}
          validation={checkInput(hourRateFactor.toString())}
          id={user.userId.toString()}
          value={hourRateFactor.toString()}
          onValueChanged={(input) => {
            if (checkInput(input) === "success") {
              onHourRateFactorChange(parseFloat(input));
            }
            if (input === "") {
              onHourRateFactorChange(0);
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};
