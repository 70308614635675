import * as React from "react";
import { useEffect, useState } from "react";
import { Input } from "../common/Input";
import { FormSelect } from "../common/FormSelect";
import { Button } from "@mui/material";

import { routes } from "../../app/routing";
import { Link } from "react-router-dom";
import {
  getSettingsFromLocalStorage,
  saveSettingsToLocalStorage,
} from "../../app/localStorage";
import { Validation } from "./index";
import { fetchWorkspaces, Workspace } from "../../services/fetchData";
import { styled } from "@mui/styles";

const StyledPageContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});
const StyledSettingsContainer = styled("div")({
  width: "100%",
  maxWidth: "800px",
  marginTop: "30px",
});

type ValidationType = Validation | undefined;

//für den input der ein und ausgeblendet wird, erstellen wir einen lokalen mit null und einen
// globalen typ ohne null
type SettingsLocal = {
  selectedWorkspaceId: string | null;
  inputApiToken: string;
};

export const SettingPage = () => {
  const [validation, setValidation] = useState<ValidationType>(undefined);
  const [workspaces, setWorkspaces] = useState<Workspace[] | null>(null);
  const [settings, setSettings] = useState<SettingsLocal>(
    getSettingsFromLocalStorage() ?? {
      selectedWorkspaceId: null,
      inputApiToken: "",
    }
  );

  // everytime when input change -> check validation -> get Data from workspace API
  useEffect(() => {
    const getWorkspaceData = async (): Promise<void> => {
      try {
        const workspaces = await fetchWorkspaces(settings.inputApiToken);
        setValidation(Validation.success);
        setWorkspaces(workspaces);
      } catch (err) {
        setValidation(Validation.warning);
        console.log("CatchError", err);
      }
    };
    getWorkspaceData();
  }, [settings.inputApiToken]);

  return (
    <StyledPageContainer>
      <StyledSettingsContainer>
        <Input
          id={"api_token"}
          label={"Your private Toggl API-Token"}
          validation={validation}
          variant={"outlined"}
          value={settings.inputApiToken}
          onValueChanged={(changedToken) =>
            setSettings((prev: SettingsLocal) => ({
              ...prev,
              inputApiToken: changedToken,
            }))
          }
        />

        {workspaces && (
          <FormSelect
            label={"Wähle deinen Workspace"}
            menuItems={workspaces}
            value={settings.selectedWorkspaceId}
            onValueChange={(newSelectedWorkspaceId) =>
              setSettings((prev: SettingsLocal) => ({
                ...prev,
                selectedWorkspaceId: newSelectedWorkspaceId,
              }))
            }
          />
        )}

        {settings.selectedWorkspaceId && (
          <Link to={routes.HOME}>
            <Button
              fullWidth
              onClick={() => {
                const { selectedWorkspaceId, inputApiToken } = settings;
                if (selectedWorkspaceId) {
                  saveSettingsToLocalStorage({
                    selectedWorkspaceId,
                    inputApiToken,
                  });
                }
              }}
              variant="contained"
            >
              Weiter
            </Button>
          </Link>
        )}
      </StyledSettingsContainer>
    </StyledPageContainer>
  );
};
