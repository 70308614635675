import {
  TogglTimeEntries,
  TogglTimeEntriesArray,
} from "../../../../services/fetchData";
import {
  Filter,
  HourRateFactor,
  Project,
  SectionType,
  Tag,
} from "../../../../app/types";
import { Report, ReportRow, Section, UsersDuration } from "../types";
import {
  totalTimeAndTotalDiscountOfSections,
  totalTimeOfARow,
  totalTimeOfCombinedTimeEntry,
} from "./totalConverter";
import {
  convertDateToStringWithoutTime,
  formatStartAndEndDate,
  groupBy,
  normalizeDescription,
  roundTimeToNext5,
} from "../../../../utils";
import { createJiraUrl, createTogglUrl } from "./createUrls";
import { getCustomerShortcuts } from "./getCustomerShortcuts";
import { mapProjectIdToProjectName } from "./mapProjectIdToProjectName";

export function reportGenerator(
  timeEntries: TogglTimeEntriesArray,
  hourRateFactors: HourRateFactor,
  filterState: Filter,
  selectedWorkspaceId: number,
  projects: Project[]
) {
  const convertToReport = (): Report => {
    const normalizedTimeEntries = normalizeTimeEntries(timeEntries);
    const sections = convertToSections(normalizedTimeEntries);
    const [totalTime, totalDiscount] =
      totalTimeAndTotalDiscountOfSections(sections);
    const customerShortcuts = getCustomerShortcuts(sections);
    const reportTitle = getReportTitle();

    return {
      sections,
      totalTime,
      totalDiscount,
      totalTimeWithoutDiscount: totalTime - totalDiscount,
      title: reportTitle,
      customerShortcuts,
    };
  };

  const createSection = (
    sectionType: string,
    tagTimeEntries: TogglTimeEntriesArray,
    isError: boolean
  ): Section => {
    let rows = convertDataToRows(tagTimeEntries).sort(
      (a, b) => a.totalUsersTime - b.totalUsersTime
    );
    const totalTime = totalTimeOfARow(rows);

    return {
      sectionType: sectionType,
      rowsData: rows,
      totalTime,
      isError,
    };
  };

  const convertToSections = (timeEntries: TogglTimeEntriesArray): Section[] => {
    const tags = collectAllOccuringTags(timeEntries);

    const sections = [];

    // section without Tags or only Discount tag
    const timeEntriesWithoutTag = timeEntries.filter(
      (timeEntry) =>
        timeEntry.tags.length === 0 ||
        (timeEntry.tags.length === 1 && timeEntry.tags[0] === Tag.Discount)
    );
    if (timeEntriesWithoutTag.length > 0) {
      const sectionsWithoutTag = createSection(
        SectionType.NoTag,
        timeEntriesWithoutTag,
        true
      );
      sections.push(sectionsWithoutTag);
    }

    // section without Projects
    const timeEntriesWithoutProject = timeEntries.filter(
      (timeEntry) => timeEntry.pid === null
    );
    if (timeEntriesWithoutProject.length > 0) {
      const sectionsWithoutProject = createSection(
        SectionType.NoProject,
        timeEntriesWithoutProject,
        true
      );
      sections.push(sectionsWithoutProject);
    }

    // TimeEntries with Tags
    const timeEntriesWithTags = tags.map((sectionType: string) => {
      const timeEntriesByTag = timeEntries.filter(
        (timeEntry) =>
          timeEntry.tags.includes(sectionType) && timeEntry.pid !== null
      );
      return createSection(sectionType, timeEntriesByTag, false);
    });
    const sortedtimeEntriesWithTags = timeEntriesWithTags.sort((a, b) => {
      if (
        a.sectionType === SectionType.Others &&
        b.sectionType !== SectionType.Others
      ) {
        return 1;
      }

      if (
        a.sectionType !== SectionType.Others &&
        b.sectionType === SectionType.Others
      ) {
        return -1;
      }
      if (a.totalTime < b.totalTime) return 1;
      if (a.totalTime > b.totalTime) return -1;

      return 0;
    });

    return [...sections, ...sortedtimeEntriesWithTags];
  };

  //combine many timeEntries to one
  const createReportRow = (timeEntries: TogglTimeEntriesArray): ReportRow => {
    let newUserDuration: UsersDuration[] = [];
    let sumOfDiscountTime = 0;

    const togglUrl = createTogglUrl(
      Number(selectedWorkspaceId),
      filterState.selectedProjectId !== null && timeEntries[0].pid !== null
        ? filterState.selectedProjectId
        : 0,
      convertDateToStringWithoutTime(filterState.timeRange.startDate),
      convertDateToStringWithoutTime(filterState.timeRange.endDate),
      timeEntries[0].description
    );

    timeEntries.forEach((timeEntry: TogglTimeEntries) => {
      //hole den aktuellen faktor aus userHourRates
      const userHourRateFactor = hourRateFactors[timeEntry.uid];
      const factor = userHourRateFactor ?? 1;

      // sum up Discount
      if (timeEntry.tags.includes(Tag.Discount)) {
        sumOfDiscountTime = sumOfDiscountTime + timeEntry.dur * factor;
      }
      newUserDuration.push({
        userId: timeEntry.uid,
        dur: timeEntry.dur * factor,
        username: timeEntry.username,
      });
    });

    return {
      description: timeEntries[0].description,
      jiraUrl: createJiraUrl(timeEntries[0].description),
      togglUrl: togglUrl,
      // summed up discount time
      discountTime: roundTimeToNext5(sumOfDiscountTime),
      //bring timeEntries together to a user
      usersTime: manyUsersDurationsToOne(newUserDuration), //gerundet
      //calcualte the time of a user
      totalUsersTime: totalTimeOfCombinedTimeEntry(
        manyUsersDurationsToOne(newUserDuration)
      ),
    };
  };

  const convertDataToRows = (
    timeEntries: TogglTimeEntriesArray
  ): ReportRow[] => {
    //group by description
    const groupedByDescription = groupBy(timeEntries, (it) => it.description);

    const rows = Object.values(groupedByDescription).map((timeEntries) => {
      return createReportRow(timeEntries);
    });

    return rows;
  };

  const collectAllOccuringTags = (
    timeEntries: TogglTimeEntriesArray
  ): string[] => {
    const collectedTags: string[] = [];
    timeEntries.forEach((timeEntry: TogglTimeEntries) => {
      //nur berücksichtigen wenn projekt zugeordnet
      if (timeEntry.pid !== null) {
        timeEntry.tags.forEach((tag: string) => {
          // wenn sectionType noch nicht gespeichert wurde und sectionType nicht rabatt ist
          if (!collectedTags.includes(tag) && tag !== Tag.Discount) {
            collectedTags.push(tag);
          }
        });
      }
    });
    return collectedTags;
  };

  const normalizeTimeEntries = (
    timeEntries: TogglTimeEntriesArray
  ): TogglTimeEntriesArray => {
    //copy of timeEntries ATTENTION!!! it a nested object
    // mak a new Array, copy pointer
    const copyOfTimeEntries = timeEntries.map((timeEntry) => ({
      ...timeEntry,
    }));

    copyOfTimeEntries.map(
      (it) => (it.description = normalizeDescription(it.description))
    );

    return copyOfTimeEntries;
  };

  // combine all timeEntries of a user to one UsersTime
  const manyUsersDurationsToOne = (
    usersTime: UsersDuration[]
  ): UsersDuration[] => {
    //group by UserIds
    const groupedByUserId = groupBy(usersTime, (it) => it.userId);
    //info groupedByUserId Beispiel
    //2940788:[
    //  {userId: 2940788, dur: 9987000, username: 'Philmeinwelt'},
    //  {userId: 2940788, dur: 9987000, username: 'Philmeinwelt'}
    //]

    //sum up all usertimes of one user
    const reducedUserTimes = Object.values(groupedByUserId).map(
      (userTimes: UsersDuration[]) => {
        let sum = 0;

        userTimes.forEach((userTime: UsersDuration) => (sum += userTime.dur));

        return {
          userId: userTimes[0].userId,
          dur: roundTimeToNext5(sum),
          username: userTimes[0].username,
        };
      }
    );

    return reducedUserTimes;
  };

  const getReportTitle = (): string => {
    let projectName = null;

    projectName = mapProjectIdToProjectName(
      filterState?.selectedProjectId,
      projects
    );

    const newTitle =
      projectName &&
      `Leistungsübersicht vom ${formatStartAndEndDate({
        startDate: filterState.timeRange.startDate,
        endDate: filterState.timeRange.endDate,
      })} - ${projectName ?? ""}`;

    return newTitle ?? "";
  };

  return convertToReport;
}
