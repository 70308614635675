import TableRow from "@mui/material/TableRow";
import { ReportRow } from "../types";
import { convertMillisecondsToString } from "../../../../utils";
import React, { CSSProperties } from "react";
import { ReportTableTitleSubRow } from "./ReportTableTitleSubRow";
import { UserDurationsRow } from "../UserDurationsRow";

import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material";

const StyledTableCellDiscount = styled(TableCell)(({ theme }) => ({
  "&&&": {
    color: theme.palette.table.textHighlight,
    backgroundColor: theme.palette.table.backgroundHighlight,
  },
}));

interface Props {
  style: CSSProperties;
  row: ReportRow;
  isCustomerViewChecked: boolean;
}

export const ReportTableRow = ({
  style,
  row,
  isCustomerViewChecked,
}: Props) => {
  return (
    <>
      <ReportTableTitleSubRow
        style={style}
        totalUsersTime={row.totalUsersTime}
        description={row.description}
        jiraUrl={row.jiraUrl}
        togglUrl={row.togglUrl}
        isCustomerViewChecked={isCustomerViewChecked}
      />
      {row.discountTime > 0 && (
        <TableRow style={style}>
          <StyledTableCellDiscount size="small" colSpan={1}>
            Rabatt
          </StyledTableCellDiscount>
          <StyledTableCellDiscount align={"right"} size="small" colSpan={2}>
            {convertMillisecondsToString(-row.discountTime)}
          </StyledTableCellDiscount>
        </TableRow>
      )}
      {!isCustomerViewChecked && (
        <UserDurationsRow style={style} usersTime={row.usersTime} />
      )}
    </>
  );
};
