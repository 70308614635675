import {
  customerShortcutRegex,
  ReportRow,
  Section,
  Shortcut,
  Status,
} from "../types";
import { groupBy } from "../../../../utils";

export const getCustomerShortcuts = (sections: Section[]): Shortcut[] => {
  // get all rows (tasks)
  const timeEntriesFromSections = sections
    .map((section) => section.rowsData)
    .flat();

  const collectedCustomerShortcuts = timeEntriesToShortCut(
    timeEntriesFromSections
  );

  const sortedCollectedCustomerShortcuts = collectedCustomerShortcuts.sort(
    (a, b) => {
      return b.shortcutCount - a.shortcutCount;
    }
  );

  // Validation
  sortedCollectedCustomerShortcuts.forEach((it, index) => {
    if (index > 1) {
      //mehr als 2 Shortcuts pro Projekt kommen normalerweise nie vor!
      it.shortcutStatus = Status.error;

      // Alle rows die nur 1-2mal vorkommen, sind wahrscheinlich fehlerhaft
      if (it.shortcutCount < 3) {
        it.shortcutStatus = Status.warning;
      }
    }
  });

  return sortedCollectedCustomerShortcuts;
};

const timeEntriesToShortCut = (timeEntries: ReportRow[]): Shortcut[] => {
  const timeEntriesWithCustomerShortcut = timeEntries.filter(
    // info achtung bei verwendung von test() und verwendung von regex g global
    (it) => customerShortcutRegex.test(it.description)
  );

  const groupedByShortcuts = groupBy(
    timeEntriesWithCustomerShortcut,
    (it): string => {
      const splitted = it.description.match(customerShortcutRegex);
      if (splitted !== null) {
        const customerShortcutString = splitted[0].split(/-/g)[0];
        return customerShortcutString;
      } else {
        throw new Error("Das kann nicht sein! groupBy");
      }
    }
  );

  //convert to type Shortcut
  //INT: (5) [{…}, {…}, {…}, {…}, {…}]
  //STO: [{…}]
  // to {shortcut:INT,shortcutCount:5,shortcutStatus:"success"}
  return Array.from(Object.entries(groupedByShortcuts)).map(
    ([shortcut, items]): Shortcut => {
      const shortcutCount: number = items.length;
      const shortcutStatus: Status = Status.success;
      return { shortcut, shortcutCount, shortcutStatus };
    }
  );
};
