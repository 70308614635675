import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface InputProps {
  validation?: "success" | "warning" | undefined;
  onValueChanged: (value: string) => void;
}

type Props = InputProps & Omit<TextFieldProps, "onChange" | "color">; // onChange can not be used

export function Input({
  validation,
  value,
  onValueChanged,
  ...textFieldProps //you don't need it, but it's nice to have :)
}: Props) {
  // set state when you change something
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onValueChanged(e.target.value);
  };
  return (
    <TextField
      onChange={handleInput}
      color={validation}
      value={value}
      fullWidth
      {...textFieldProps}
    />
  );
}
