import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material";

export const StyledTagTableCell = styled(TableCell)(({ theme }) => ({
  "&&&": {
    backgroundColor: theme.palette.table.backgroundDarker,
    color: theme.palette.table.textHeader,
    fontWeight: "bold",
    padding: "20",
    fontFamily: "Roboto",
  },
}));

export const StyledTableCellGray = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.table.backgroundDarker,
  fontWeight: "bold",
  fontFamily: "Roboto",
  "&.right": {
    textAlign: "right",
  },
  "&.left": {
    textAlign: "left",
  },
}));
