import { useEffect, useState } from "react";
import { FormTimeRangePicker } from "../common/FormTimeRangePicker";
import { Button, FormControlLabel, styled, Switch } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { routes } from "../../app/routing";
import { FormSelect } from "../common/FormSelect";
import {
  fetchTimeEntries,
  fetchTimeEntriesWithoutProjectId,
  TogglTimeEntriesArray,
  useFetchProjects,
} from "../../services/fetchData";
import { useFilterState } from "./useFilterState";
import { Report, reportGenerator, ReportTable } from "../common/ReportTable";
import { convertToPercent, HourRate } from "../common/HourRate";
import Box from "@mui/material/Box";
import { Filter } from "../../app/types";
import { getSettingsFromLocalStorage } from "../../app/localStorage";
import { useHourRateFactors } from "../common/HourRate/useHourRateFactors";
import { mapProjectIdToProjectName } from "../common/ReportTable/converter/mapProjectIdToProjectName";
import { formatStartAndEndDate } from "../../utils";

const PageContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const Page = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
}));
const HideWhilePrinting = styled("div")({
  "@media print": { display: "none" },
});

const StyledFilterContainer = styled("form")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  marginBottom: "30px",
  flexWrap: "wrap",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    flexDirection: "column",
  },
}));

const StyledSettingsContainer = styled("div")({
  textAlign: "right",
});

export const Home = () => {
  // info old version : const [projects, setProjects] = useState<Project[]>([]);
  const projects = useFetchProjects();
  const [filterState, setFilterState] = useFilterState();
  const [timeEntries, setTimeEntries] = useState<TogglTimeEntriesArray>([]);
  const [isCustomerView, setIsCustomerView] = useState(false);
  const [userHourRateFactors] = useHourRateFactors();
  const [report, setReport] = useState<Report | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCustomerView(event.target.checked);
  };

  //lade  projektdaten
  useEffect(() => {
    const timeEntries = async (): Promise<void> => {
      try {
        let timeEntries = await fetchTimeEntries(
          filterState.timeRange.startDate,
          filterState.timeRange.endDate,
          filterState.selectedProjectId
        );

        // Without Project
        const timeEntriesWithoutProject =
          await fetchTimeEntriesWithoutProjectId(
            filterState.timeRange.startDate,
            filterState.timeRange.endDate
          );

        timeEntries = [...timeEntries, ...timeEntriesWithoutProject];

        if (timeEntries) {
          setTimeEntries(timeEntries);
        }
      } catch (err) {
        console.log("CatchError", err);
      }
    };

    timeEntries();
  }, [filterState]);

  useEffect(() => {
    const selectedWorkspaceId =
      getSettingsFromLocalStorage()?.selectedWorkspaceId;
    if (selectedWorkspaceId !== undefined) {
      const convertToReport = reportGenerator(
        timeEntries,
        convertToPercent(userHourRateFactors),
        filterState,
        Number(selectedWorkspaceId),
        projects
      );
      setReport(convertToReport());
    }
  }, [userHourRateFactors, timeEntries, filterState, projects]);

  return (
    <>
      <PageContainer>
        <Page>
          <HideWhilePrinting>
            <StyledSettingsContainer>
              <Link to={routes.SETTINGPAGE}>
                <Button
                  startIcon={<SettingsIcon />}
                  color="info"
                  variant={"text"}
                >
                  Settings
                </Button>
              </Link>
            </StyledSettingsContainer>
            <StyledFilterContainer>
              <FormSelect
                label={"Projekt"}
                menuItems={projects}
                value={filterState.selectedProjectId}
                onValueChange={(newSelectedProject) => {
                  setFilterState((prev: Filter) => ({
                    ...prev,
                    selectedProjectId: newSelectedProject
                      ? Number(newSelectedProject)
                      : null,
                  }));
                }}
              />
              <FormTimeRangePicker
                label={"Datum"}
                selectedRange={filterState.timeRange}
                setSelectedRange={(newTimeRange) => {
                  setFilterState((prev: Filter) => ({
                    ...prev,
                    timeRange: newTimeRange,
                  }));
                }}
              />

              <HourRate />
            </StyledFilterContainer>
            <Box mb={5} textAlign={"right"}>
              <FormControlLabel
                control={
                  <Switch checked={isCustomerView} onChange={handleChange} />
                }
                label={"Kundenansicht"}
              />
              <Button
                variant={"contained"}
                onClick={() => {
                  document.title = `Report-${mapProjectIdToProjectName(
                    filterState.selectedProjectId,
                    projects
                  )}-${formatStartAndEndDate(filterState.timeRange)
                    .split(" ")
                    .join("")}`;
                  window.print();
                }}
              >
                Drucken
              </Button>
            </Box>
          </HideWhilePrinting>

          {report !== null &&
            (report.sections.length === 0 ? (
              <h3>Keine Einträge gefunden</h3>
            ) : (
              <ReportTable
                report={report}
                isCustomerViewChecked={isCustomerView}
              />
            ))}
        </Page>
      </PageContainer>
    </>
  );
};
