import { Home } from "../../components/Home";
import { SettingPage } from "../../components/Settings";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HOME, SETTINGPAGE } from "./routes";
import { getSettingsFromLocalStorage } from "../localStorage";

// info React Router Dom Auth Example https://reactrouter.com/docs/en/v6/examples/auth
function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = getSettingsFromLocalStorage()?.inputApiToken ? true : false;

  if (!auth) {
    return <Navigate to={SETTINGPAGE} replace />;
  }

  return children;
}

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={HOME}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route path={SETTINGPAGE} element={<SettingPage />} />
        <Route path={"*"} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};
