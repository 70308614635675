// convert Object To Filter

import { convertDateToStringWithoutTime } from "../../utils";
import { Filter } from "../../app/types";

export const flattenFilterObject = (filterObject: Filter) => ({
  selectedProjectId: filterObject.selectedProjectId?.toString() ?? "",
  startDate: convertDateToStringWithoutTime(filterObject.timeRange.startDate),
  endDate: convertDateToStringWithoutTime(filterObject.timeRange.endDate),
});
