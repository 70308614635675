import { Filter, HourRateFactor, SettingsGlobal } from "../types";

export const localStorage = (key: string): any | null => {
  const item = window.localStorage.getItem(key);
  return item != null ? JSON.parse(item) : null;
};

export const getSettingsFromLocalStorage = (): SettingsGlobal | null => {
  return localStorage("settings");
};

export const getFilterFromLocalStorage = (): Filter | null => {
  const filterFromStorage = localStorage("filter");
  if (filterFromStorage) {
    return {
      selectedProjectId: filterFromStorage.selectedProjectId,
      timeRange: {
        startDate: new Date(filterFromStorage.timeRange.startDate),
        endDate: new Date(filterFromStorage.timeRange.endDate),
      },
    };
  } else return null;
};

export const getHourRateFactorsFromLocalStorage = (): HourRateFactor | null => {
  const hourRateFromStorage = localStorage("hourRate");
  if (hourRateFromStorage) {
    return hourRateFromStorage;
  } else {
    return null;
  }
};

export const saveToLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const saveFilterToLocalStorage = (filter: Filter) => {
  saveToLocalStorage("filter", filter);
};

export const saveSettingsToLocalStorage = (settings: SettingsGlobal) => {
  saveToLocalStorage("settings", settings);
};

export const saveHourRateToLocalStorage = (hourRateFactors: HourRateFactor) => {
  saveToLocalStorage("hourRate", hourRateFactors);
};
