import { styled, Table, TableBody } from "@mui/material";
import { HourRateRow } from "./HourRateRow";
import { SetStateAction } from "react";
import { HourRateFactor, User } from "../../../app/types";

const StyledHourRateTableRoot = styled(Table)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.table.background,

  "& td,& th": {
    border: "none",
    paddingTop: "4px",
    paddingLeft: "0px",
    paddingRight: "0px",

    fontSize: "15px",
  },
  "& th": {
    backgroundColor: theme.palette.table.backgroundRow,
  },
}));

interface Props {
  hourRateFactors: HourRateFactor;
  onHourRateFactorsChanged: (
    hourRateFactors: SetStateAction<HourRateFactor>
  ) => void;
  users: User[];
}

export function UserHourRateInputTable({
  hourRateFactors,
  onHourRateFactorsChanged,
  users,
}: Props) {
  return (
    <StyledHourRateTableRoot>
      <TableBody>
        {users &&
          users.map((user: User) => {
            return (
              <HourRateRow
                key={user.userId}
                hourRateFactor={hourRateFactors[user.userId]}
                onHourRateFactorChange={(input) => {
                  // wenn ich hier nicht nochmal spreade vor der übergabe dann habe ich einen
                  // Fehler weil ich bei setHourRateFactors kein SetStateAction<HourRateFactor>
                  // verwende
                  onHourRateFactorsChanged((prev) => ({
                    ...prev,
                    [user.userId]: input,
                  }));
                }}
                user={user}
              />
            );
          })}
      </TableBody>
    </StyledHourRateTableRoot>
  );
}
