import { useEffect, useState } from "react";
import { useFetchUsers } from "../../../services/fetchData";
import {
  getHourRateFactorsFromLocalStorage,
  saveHourRateToLocalStorage,
} from "../../../app/localStorage";
import { AlertDialog } from "./AlertDialog";
import { HourRateDialog } from "./HourRateDialog";
import { useHourRateFactors } from "./useHourRateFactors";
import { HourRateFactor } from "../../../app/types";

export const convertToPercent = (
  hourRateFactors: HourRateFactor
): HourRateFactor => {
  const hourRateFactorsInPercentage = Object.fromEntries(
    Array.from(Object.entries(hourRateFactors)).map(([key, value]) => [
      key,
      value / 100,
    ])
  );
  return hourRateFactorsInPercentage;
};

export const HourRate = () => {
  const users = useFetchUsers();

  // useHourRateFactors() speichert alles in die url
  const [hourRateFactors, setHourRateFactors] = useHourRateFactors();
  const [openOverwriteHourRatesDialog, setOpenOverwriteHourRatesDialog] =
    useState(false);

  const handleOpenOverwriteHourRatesDialog = () => {
    setOpenOverwriteHourRatesDialog(true);
  };

  const handleCloseOverwriteHourRatesDialog = () => {
    setOpenOverwriteHourRatesDialog(false);
  };

  useEffect(() => {
    // if url is not empty, then check if it is different to localStorage
    if (hourRateFactors) {
      const localStorageObject = getHourRateFactorsFromLocalStorage();

      //iff url different
      if (
        localStorageObject !== null &&
        Object.entries(hourRateFactors).length !== 0 &&
        JSON.stringify(localStorageObject) !== JSON.stringify(hourRateFactors)
      ) {
        handleOpenOverwriteHourRatesDialog();
      }
    }
  }, []);

  useEffect(() => {
    const getInitSearchParamObject = (
      hourRates: HourRateFactor
    ): HourRateFactor => {
      let initHourRateFactors = {};

      users.forEach(
        (user) =>
          (initHourRateFactors = {
            ...initHourRateFactors,
            [`${user.userId}`]: 100,
          })
      );

      return {
        ...initHourRateFactors,
        //get data from localStorag
        ...(getHourRateFactorsFromLocalStorage() ?? {}),
        //get data from searchparams
        ...(hourRates ?? {}),
      };
    };

    // ich bekomme die aktuellen hourrates und geb sie an getInitSearchParamObject weiter
    setHourRateFactors((prev) => getInitSearchParamObject(prev));
  }, [users]);

  useEffect(() => {
    if (Object.keys(hourRateFactors).length !== 0) {
      saveHourRateToLocalStorage(hourRateFactors);
    }
  }, [hourRateFactors]);

  return (
    <>
      <HourRateDialog
        users={users}
        onHourRateFactorsChanged={(newHourRateFactors) =>
          setHourRateFactors(newHourRateFactors)
        }
        hourRateFactors={hourRateFactors}
      />
      {/* Dialog if overwrite older hourRates*/}
      <AlertDialog
        open={openOverwriteHourRatesDialog}
        close={handleCloseOverwriteHourRatesDialog}
      />
    </>
  );
};
