import { SvgIcon, useTheme } from "@mui/material";

export const TogglIcon = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      style={{
        fill: theme.palette.neutral.main,
        width: "18",
        height: "18",
      }}
      viewBox={"0 0 14 14"}
    >
      <path d="M 7,1 C 3.6865,1 1,3.685 1,7 c 0,3.315 2.6865,6 6,6 3.315,0 6,-2.685 6,-6 C 13,3.685 10.315,1 7,1 Z m -0.42,2.335 0.84,0 0,4.18 -0.84,0 0,-4.18 z M 7,10.0775 c -1.62,-0.001 -2.9325,-1.315 -2.932,-2.934 0,-1.32 0.8835,-2.478 2.157,-2.8275 l 0,0.855 c -0.814,0.32 -1.349,1.105 -1.3475,1.98 0,1.1725 0.9515,2.122 2.124,2.1215 1.172,-0.001 2.122,-0.9515 2.1215,-2.124 0,-0.8725 -0.535,-1.656 -1.347,-1.975 l 0,-0.8585 c 1.275,0.35 2.157,1.509 2.157,2.83 0,1.62 -1.313,2.932 -2.9325,2.932 z" />
    </SvgIcon>
  );
};
