import {
  getFilterFromLocalStorage,
  saveFilterToLocalStorage,
} from "../../app/localStorage";
import { SetStateAction, useMemo } from "react";

import { flattenFilterObject } from "./flattenForSearchParams";
import { useSearchParamsNonOverwriting } from "../../utils";
import { Filter } from "../../app/types";

export function useFilterState(
  initFilterObject?: Filter
): [Filter, (newFilter: SetStateAction<Filter>) => void] {
  const convertToFilterObject = (searchParams: URLSearchParams): Filter => {
    const projectIdStringParam = searchParams.get("selectedProjectId");
    const startDateStringParam = searchParams.get("startDate");
    const endDateStringParam = searchParams.get("endDate");

    //if url params, use them first
    if (
      startDateStringParam !== null &&
      endDateStringParam !== null &&
      projectIdStringParam !== null
    ) {
      const startDateFromUrlAsDate = new Date(startDateStringParam);
      const endDateFromUrlAsDate = new Date(endDateStringParam);

      return {
        selectedProjectId: Number(projectIdStringParam),
        timeRange: {
          startDate: startDateFromUrlAsDate,
          endDate: endDateFromUrlAsDate,
        },
      };
    } else {
      throw new Error("no searchParams found");
    }
  };

  const convertToFilterObjectAndNullCheck = (
    searchParams: URLSearchParams
  ): Filter | null => {
    //try catch to be dry
    try {
      return convertToFilterObject(searchParams);
    } catch {
      return null;
    }
  };

  // das filter object wird default initialisiert, wenn in der url params vorhanden sind, dann
  // wird der default wert überschrieben, wenn keine params vorhanden sind dann wird es mit den
  // localstoarage daten überschrieben, wenn vorhanden.
  const getInitFilterObject = (): Filter => {
    //default
    return {
      //info here null check necessary, error while overwriting
      selectedProjectId: null,
      timeRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      //get data from local storage
      ...(getFilterFromLocalStorage() ?? {}),
      //get data from searchparams
      ...(convertToFilterObjectAndNullCheck(
        new URL(document.URL).searchParams
      ) ?? {}),
    };
  };

  const [searchParams, saveSearchParamsInUrl] = useSearchParamsNonOverwriting(
    flattenFilterObject(
      initFilterObject ? initFilterObject : getInitFilterObject()
    )
  );

  //like useEffect - get Search Params when searchParams change
  let returnSearchParam = useMemo(() => {
    //daten aus url holen und schaun ob filter drinnen ist, wenn ja dann mit filter
    // weiterarbeiten.
    const filter = convertToFilterObject(searchParams);
    saveFilterToLocalStorage(filter);
    return filter;
  }, [searchParams]);

  const saveFilterStateToUrl = (newFilter: SetStateAction<Filter>) => {
    const filter =
      typeof newFilter === "function"
        ? newFilter(convertToFilterObject(searchParams))
        : newFilter;

    const convertedToSearchParams = flattenFilterObject(filter);

    saveSearchParamsInUrl(convertedToSearchParams);
  };
  return [returnSearchParam, saveFilterStateToUrl];
}
