import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { convertMillisecondsToString } from "../../../../utils";
import { styled } from "@mui/material";
import { ReportTableTitle } from "./ReportTableTitle";
import { ExternalLinks } from "./ExternalLinks";
import { CSSProperties } from "react";

const StyledTimeEntryTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.table.backgroundRow,
  paddingBottom: 0,
  borderTop: `1px solid ${theme.palette.table.border}`,
  "& .time": {
    textAlign: "right",
    paddingBottom: 0,
  },
}));

const StyledTitleCell = styled(TableCell)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingBottom: 0,
});

interface Props {
  description: string;
  totalUsersTime: number;
  jiraUrl: string | undefined;
  togglUrl: string;
  isCustomerViewChecked: boolean;
  style: CSSProperties;
}

//
export const ReportTableTitleSubRow = (props: Props) => {
  return (
    <StyledTimeEntryTableRow style={props.style}>
      <StyledTitleCell className={"cell"} size="small">
        <ReportTableTitle
          jiraUrl={props.jiraUrl}
          description={props.description}
        />
        <ExternalLinks
          jiraUrl={props.jiraUrl}
          togglUrl={props.togglUrl}
          isCustomerViewChecked={props.isCustomerViewChecked}
        />
      </StyledTitleCell>
      <TableCell size="small" className={"time"} colSpan={2}>
        {convertMillisecondsToString(props.totalUsersTime)}
      </TableCell>
    </StyledTimeEntryTableRow>
  );
};
