import { UsersDuration } from "./types";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { convertMillisecondsToString } from "../../../utils";
import React, { CSSProperties } from "react";
import { styled } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&&&": {
    color: theme.palette.table.textLight,
    fontSize: "11px",
    paddingTop: 0,
  },
}));

const StyledUserTime = styled("span")({
  paddingRight: "20",
});

const StyledDevider = styled("span")({
  paddingRight: "4",
  paddingLeft: "4",
});

interface Props {
  usersTime: UsersDuration[];
  style: CSSProperties;
}

export const UserDurationsRow = (props: Props) => {
  return (
    <TableRow style={props.style}>
      <StyledTableCell size="small" colSpan={2}>
        {props.usersTime.map((oneUser: UsersDuration) => {
          return (
            <React.Fragment key={oneUser.username + oneUser.dur}>
              <StyledUserTime>
                {oneUser.username[0]}
                <StyledDevider>|</StyledDevider>
                {convertMillisecondsToString(oneUser.dur)}
              </StyledUserTime>
            </React.Fragment>
          );
        })}
      </StyledTableCell>
    </TableRow>
  );
};
