import { TotalResultRow } from "./TotalResultRow";
import { styled, Table, TableBody, useTheme } from "@mui/material";

const StyledTotalResultTable = styled(Table)({
  maxWidth: "350px",
  "& .bold": {
    fontWeight: "bold",
  },
});

interface Props {
  totalTime: number;
  totalDiscount: number;
  totalTimeWithoutDiscount: number;
  isCustomerViewChecked: boolean;
}

export const TotalResults = ({
  totalTime,
  totalDiscount,
  totalTimeWithoutDiscount,
  isCustomerViewChecked,
}: Props) => {
  const theme = useTheme();
  const size =
    totalDiscount > 0 ? "small" : isCustomerViewChecked ? "small" : "medium";
  const color =
    totalDiscount > 0 ? "transparent" : theme.palette.table.backgroundDarker;
  return (
    <>
      <StyledTotalResultTable>
        <TableBody>
          <TotalResultRow
            label={totalDiscount > 0 ? `Gesamt (ohne Rabatt)` : `Gesamt`}
            totalTime={totalTime}
            isCustomerViewChecked={isCustomerViewChecked}
            className={totalDiscount > 0 ? "" : "bold"}
            bgColor={color}
            size={size}
          />

          {totalDiscount > 0 && (
            <>
              <TotalResultRow
                label={"Rabatt"}
                totalTime={-totalDiscount}
                bgColor={theme.palette.table.backgroundHighlight}
                isCustomerViewChecked={isCustomerViewChecked}
                textColor={theme.palette.table.textHighlight}
                size={"small"}
              />

              <TotalResultRow
                className={"bold"}
                label={"Gesamt"}
                totalTime={totalTimeWithoutDiscount}
                isCustomerViewChecked={isCustomerViewChecked}
                bgColor={theme.palette.table.backgroundDarker}
                size={isCustomerViewChecked ? "small" : "medium"}
              />
            </>
          )}
        </TableBody>
      </StyledTotalResultTable>
    </>
  );
};
