import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";

import { useState } from "react";
import { UserHourRateInputTable } from "./UserHourRateInputTable";
import { HourRateFactor, User } from "../../../app/types";

const StyledActionBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

interface Props {
  users: User[];
  hourRateFactors: HourRateFactor;
  onHourRateFactorsChanged: (changedHourRateFactors: HourRateFactor) => void;
}

export const HourRateDialog = ({
  users,
  hourRateFactors,
  onHourRateFactorsChanged,
}: Props) => {
  const [openHourRateDialog, setOpenHourRateDialog] = useState(false);
  const [newHourRateFactors, setNewHourRateFactors] = useState<HourRateFactor>(
    {}
  );

  const handleClickOpenHourRateDialog = () => {
    setNewHourRateFactors(hourRateFactors);
    setOpenHourRateDialog(true);
  };

  const handleCloseHourRateDialog = () => {
    setOpenHourRateDialog(false);
  };

  const resetHourRateFactors = () => {
    let resetHourRateFactors = {};
    users.forEach(
      (user) =>
        (resetHourRateFactors = {
          ...resetHourRateFactors,
          [`${user.userId}`]: 100,
        })
    );
    setNewHourRateFactors(resetHourRateFactors);
  };
  return (
    <>
      <IconButton onClick={handleClickOpenHourRateDialog}>
        <PaidRoundedIcon />
      </IconButton>
      {/*hier öffnet der hourRate Dialog*/}
      <Dialog open={openHourRateDialog} onClose={handleCloseHourRateDialog}>
        <DialogTitle>Stundensätze ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Trage hier die Stundensätze in % ein. Sie werden für alle Projekte
            automatisch gespeichert.
          </DialogContentText>
          <UserHourRateInputTable
            hourRateFactors={newHourRateFactors}
            onHourRateFactorsChanged={setNewHourRateFactors}
            users={users}
          />
          <StyledActionBox>
            <Button variant={"outlined"} onClick={() => resetHourRateFactors()}>
              clear
            </Button>

            <Button
              variant={"contained"}
              onClick={() => {
                onHourRateFactorsChanged(newHourRateFactors);
                handleCloseHourRateDialog();
              }}
            >
              Ok
            </Button>
          </StyledActionBox>
        </DialogContent>
      </Dialog>
    </>
  );
};
