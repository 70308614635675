import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material";

type MenuItems = {
  label: string;
  value: number;
};

const StyledBox = styled(Box)({
  minWidth: 150,
});

interface FormSelectProps<T> {
  label: string;
  id?: string;
  menuItems: MenuItems[];
  value: T;
  onValueChange: (value: T | string) => void;
}

//combine props
type Props<T> = FormSelectProps<T> & SelectProps<T>;

export function FormSelect<T>({
  label,
  id,
  menuItems,
  value,
  onValueChange,
  ...selectProps
}: Props<T>) {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onValueChange(event.target.value);
  };

  const inputLabelId = id ? id : label;
  return (
    <StyledBox>
      <FormControl fullWidth margin={"dense"}>
        <InputLabel id={inputLabelId}>{label}</InputLabel>
        <Select
          labelId={inputLabelId}
          value={value}
          label={label}
          onChange={handleChange}
          {...selectProps}
        >
          {menuItems.map((item: MenuItems) => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledBox>
  );
}
