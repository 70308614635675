import { Shortcut, Status } from "./types";
import * as React from "react";
import { Chip, styled } from "@mui/material";

const StyledChip = styled(Chip)({
  marginRight: "10px",
  marginBottom: "20px",
  "@media print": {
    display: "none",
  },
});

interface Props {
  customerShortcuts: Shortcut[];
}

export const CustomerShortcuts = ({ customerShortcuts }: Props) => {
  return (
    <>
      {customerShortcuts.map(({ shortcut, shortcutCount, shortcutStatus }) => {
        return (
          <StyledChip
            key={shortcut}
            color={
              shortcutStatus === Status.success
                ? "default"
                : shortcutStatus ?? "default"
            }
            label={`${shortcut} ${shortcutCount}`}
            variant={shortcutStatus ? "filled" : "outlined"}
          />
        );
      })}
    </>
  );
};
