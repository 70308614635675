import TableRow from "@mui/material/TableRow";
import { Section } from "./types";
import { convertMillisecondsToString } from "../../../utils";
import {
  StyledTableCellGray,
  StyledTagTableCell,
} from "./tableStyling/tableStyling";
import { ReportTableRows } from "./ReportTableRow/ReportTableRows";
import { styled, Table, TableBody, TableHead, useTheme } from "@mui/material";
import { SectionType } from "../../../app/types";

const StyledTable = styled(Table)({
  marginBottom: 40,
});

interface Props {
  section: Section;
  isCustomerViewChecked: boolean;
  status: boolean;
}

export const TagSection = ({
  section,
  isCustomerViewChecked,
  status,
}: Props) => {
  const theme = useTheme();
  const size = isCustomerViewChecked ? "small" : "medium";

  function createSectionTitle(sectionType: string) {
    if (sectionType === SectionType.NoTag) return "Kein Tag vorhanden";
    if (sectionType === SectionType.NoProject)
      return "Keinem Projekt zugeteilt";

    return sectionType;
  }

  return (
    <StyledTable
      style={{
        backgroundColor:
          status === true
            ? theme.palette.error.light
            : theme.palette.table.backgroundRow,
      }}
    >
      <TableHead>
        <TableRow>
          <StyledTagTableCell colSpan={3} size={size} align="left">
            {createSectionTitle(section.sectionType)}
          </StyledTagTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <ReportTableRows
          rowsData={section.rowsData}
          isCustomerViewChecked={isCustomerViewChecked}
        />
        <TableRow>
          <StyledTableCellGray size={size}>Gesamt</StyledTableCellGray>
          <StyledTableCellGray size={size} className="right" colSpan={2}>
            <span>{convertMillisecondsToString(section.totalTime)}</span>
          </StyledTableCellGray>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};
