import { ReportRow } from "../types";
import { ReportTableRow } from "./ReportTableRow";
import { useTheme } from "@mui/material";

interface Props {
  rowsData: ReportRow[];
  isCustomerViewChecked: boolean;
}

export const ReportTableRows = ({ rowsData, isCustomerViewChecked }: Props) => {
  const theme = useTheme();
  return (
    <>
      {rowsData.map((row, key) => {
        return (
          <ReportTableRow
            /*  without Description*/
            style={
              row.description.length === 0
                ? { backgroundColor: theme.palette.error.light }
                : {}
            }
            key={row.description + key}
            row={row}
            isCustomerViewChecked={isCustomerViewChecked}
          />
        );
      })}
    </>
  );
};
