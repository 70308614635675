import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { Button, styled, TextField } from "@mui/material";
import { useState } from "react";
import { formatStartAndEndDate } from "../../../utils";
import Box from "@mui/material/Box";

const StyledContainer = styled("div")({
  position: "relative",
  top: 0,
  left: 0,
});

const StyledDateRangePickerWindow = styled("div")(({ theme }) => ({
  height: "max-content",
  position: "absolute",
  padding: 20,
  background: "#ffffff",
  left: "0px",
  boxShadow: "6px 3px 38px 0px rgba(0,0,0,0.17)",
  zIndex: "1000",

  [theme.breakpoints.down("sm")]: {
    padding: 0,
    position: "absolute",
  },
}));

type DateRange = {
  startDate: Date;
  endDate: Date;
};

interface Props {
  label: string;
  selectedRange: DateRange;
  setSelectedRange: (value: DateRange) => void;
}

export const FormTimeRangePicker = ({
  label,
  selectedRange,
  setSelectedRange,
}: Props) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [selectedRangeIntern, setSelectedRangeIntern] = useState<DateRange>();

  const handleSelect = (ranges: RangeKeyDict): void => {
    const { startDate, endDate } = ranges.selection;
    //updated ranges
    if (startDate && endDate) {
      setSelectedRangeIntern({ startDate, endDate });
    }
  };

  return (
    <>
      <StyledContainer>
        <TextField
          fullWidth
          variant={"outlined"}
          label={label}
          value={formatStartAndEndDate(selectedRange)}
          onClick={() => setShowDateRangePicker((prev) => !prev)}
          margin={"dense"}
        />
        {showDateRangePicker && (
          <StyledDateRangePickerWindow>
            <DateRangePicker
              ranges={[{ ...selectedRangeIntern, key: "selection" }]}
              weekStartsOn={1}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              months={1}
              showMonthAndYearPickers={true}
              direction={"horizontal"}
            />
            <Box textAlign={"right"}>
              <Button
                onClick={() => {
                  setShowDateRangePicker(false);
                  if (selectedRangeIntern) {
                    setSelectedRange(selectedRangeIntern);
                  }
                }}
                variant={"contained"}
              >
                Save
              </Button>
            </Box>
          </StyledDateRangePickerWindow>
        )}
      </StyledContainer>
    </>
  );
};
