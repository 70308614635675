import { HourRateFactor } from "../../../app/types";

export const convertValuesToNumbers = (
  object: Record<string, string>
): HourRateFactor => {
  // erstellt ein objekt aus einem array mit key/value paaren
  return Object.fromEntries(
    Array.from(Object.entries(object)).map(([key, value]) => [
      key,
      Number(value),
    ])
  );
};

export const convertHourRateFromUrl = (
  urlSearchParams: URLSearchParams
): HourRateFactor | null => {
  if (urlSearchParams.toString().length !== 0) {
    const hourRateArray = Array.from(urlSearchParams.entries()).filter((it) => {
      const transformedToNumber = parseFloat(it[0]);
      if (!isNaN(transformedToNumber)) {
        return true;
      }
    });

    let hourRateObject = Object.fromEntries(hourRateArray);

    return convertValuesToNumbers(hourRateObject);
  } else {
    return null;
  }
};

export const convertHourRateFactorsToSearchParams = (
  hourRateFactors: HourRateFactor
): URLSearchParams => {
  const newSearchParams = new URLSearchParams();
  Array.from(Object.entries(hourRateFactors)).forEach(([key, value]) => {
    newSearchParams.set(key, value.toString());
  });
  return newSearchParams;
};
