import React from "react";
import { AppRouter } from "./routing";
import { ContextContainer } from "./context";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

function App() {
  return (
    <ContextContainer>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </ContextContainer>
  );
}

export default App;
