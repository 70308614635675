import { fetchUsers } from "./index";
import { useEffect, useState } from "react";
import { User } from "../../app/types";

export const useFetchUsers = (init: User[] = []): User[] => {
  const [users, setUsers] = useState<User[]>(init);

  useEffect(() => {
    const getUsers = async () => {
      const fetchedUsers = await fetchUsers();
      setUsers(fetchedUsers);
    };
    getUsers();
  }, []);

  return users;
};
