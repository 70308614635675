const convertMilliSecondsToObject = (milliseconds: number) => {
  return {
    hours: Math.trunc(milliseconds / 3600000),
    minutes: Math.trunc(milliseconds / 60000) % 60,
  };
};

export const roundTimeToNext5 = (milliseconds: number): number => {
  const minutes = milliseconds / (1000.0 * 60);
  const rest = minutes % 5;
  let roundedMinutes = 0;
  if (rest >= 2.5) {
    //round up
    roundedMinutes = Math.ceil(minutes / 5) * 5;
  } else if (rest < 2.5) {
    //round off
    roundedMinutes = Math.floor(minutes / 5) * 5;
  }

  const roundedMilliSecounds = roundedMinutes * 1000 * 60;
  return roundedMilliSecounds;
};

export const convertMillisecondsToString = (milliseconds: number): string => {
  let { hours, minutes } = convertMilliSecondsToObject(Math.abs(milliseconds));
  const negOrPosSign = milliseconds < 0 ? "-" : "";
  // padStart 4 --> 04
  return `${negOrPosSign}${hours}:${minutes.toString().padStart(2, "0")}h`;
};

export const convertMillisecondsToDecimal = (milliseconds: number): string => {
  let { hours, minutes } = convertMilliSecondsToObject(Math.abs(milliseconds));
  const negOrPosSign = milliseconds < 0 ? "-" : "";
  return `${negOrPosSign}${hours},${Math.ceil((minutes / 60) * 100)}`;
};
