export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type SettingsGlobal = {
  inputApiToken: string;
  selectedWorkspaceId: string;
};

type UserId = string;
type Factor = number;
export type HourRateFactor = Record<UserId, Factor>;

export type Filter = {
  selectedProjectId: number | null;
  timeRange: DateRange;
};

export interface User {
  userId: number;
  username: string;
}

export enum Tag {
  Discount = "Rabatt",
}

export enum SectionType {
  NoTag = "NoTag",
  NoProject = "NoProject",
  Others = "Sonstiges",
}

export type Project = {
  label: string;
  value: number;
};
