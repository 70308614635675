//info wird aktuell nicht benötigt
import { createContext } from "react";

export const ProjectContext = createContext<any>(undefined);

export const ContextContainer = ({ children }: any) => {
  return (
    <ProjectContext.Provider value={{}}>{children}</ProjectContext.Provider>
  );
};
