import { Link as LinkIcon } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { TogglIcon } from "../../../../icons/svgIcons";

const StyledJiraButton = styled("div")({
  display: "none",
  "@media print": {
    display: "flex",
    alignItems: "center",
  },
}) as typeof IconButton;

const StyledTogglIconButton = styled(IconButton)({
  display: "flex",
  alignItems: "center",
  "@media print": { display: "none" },
}) as typeof IconButton;

const StyledExternalLinksContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "0px 10px",
});

interface Props {
  jiraUrl: string | undefined;
  isCustomerViewChecked: boolean;
  togglUrl: string;
}

export const ExternalLinks = (props: Props) => {
  return (
    <StyledExternalLinksContainer>
      {/* //jiraLink*/}
      {props.jiraUrl && (
        <StyledJiraButton href={props.jiraUrl} target={"_blank"}>
          <LinkIcon fontSize="small" />
        </StyledJiraButton>
      )}
      {/*  //TogglLink*/}
      {props.togglUrl && !props.isCustomerViewChecked && (
        <StyledTogglIconButton href={props.togglUrl} target={"_blank"}>
          <TogglIcon />
        </StyledTogglIconButton>
      )}
    </StyledExternalLinksContainer>
  );
};
