import { fetchProjects } from "./index";
import { useEffect, useState } from "react";
import { Project } from "../../app/types";

export const useFetchProjects = (): Project[] => {
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    const getProjects = async (): Promise<void> => {
      try {
        const projects = await fetchProjects();

        setProjects(projects);
      } catch (err) {
        console.log("Error while using useFetchProjects");
      }
    };
    getProjects();
  }, []);

  return projects;
};
