import { SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

export type SearchParamsInit =
  | string[][]
  | Record<string, string>
  | string
  | URLSearchParams
  | undefined;

export const useSearchParamsNonOverwriting = (
  init?: Record<string, string> | URLSearchParams
): [
  URLSearchParams,
  (setSearchParams: SetStateAction<SearchParamsInit>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams(init);

  const addSearchParams = (newSearchParams: SearchParamsInit) => {
    const paramsToAdd = new URLSearchParams(newSearchParams);
    let existingSearchParams = new URLSearchParams(searchParams);

    //search params adden
    Array.from(paramsToAdd.entries()).forEach(([name, value]) => {
      existingSearchParams.set(name, value);
    });

    return existingSearchParams;
  };

  const saveSearchParamsInUrl = (
    newSearchParams: SetStateAction<SearchParamsInit>
  ) => {
    const searchParamsWithAddedParams = addSearchParams(
      typeof newSearchParams === "function"
        ? // wenn es eine funktion ist, dann wird sie aufgerufen und der
          // aktuelle wert von searchparams übergeben.
          newSearchParams(searchParams)
        : newSearchParams
    );
    setSearchParams(searchParamsWithAddedParams);
  };
  return [searchParams, saveSearchParamsInUrl];
};
