export const convertDateToStringWithoutTime = (date: Date): string => {
  let day = "" + date.getDate();
  let month = "" + (date.getMonth() + 1);
  const year = date.getFullYear();

  return year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export const formatStartAndEndDate = (dateObject: DateRange): string => {
  const startDate = dateObject.startDate;
  const endDate = dateObject.endDate;
  if (
    convertDateToStringWithoutTime(startDate) ===
    convertDateToStringWithoutTime(endDate)
  ) {
    return startDate.toLocaleDateString("de");
  } else {
    return `${startDate.toLocaleDateString(
      "de"
    )} - ${endDate.toLocaleDateString("de")}`;
  }
};
