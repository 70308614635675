export const createTogglUrl = (
  selectedWorkspaceId: number,
  selectedProjectId: number,
  startDate: string,
  endDate: string,
  description: string
): string => {
  return `https://track.toggl.com/reports/detailed/${selectedWorkspaceId}/description/${
    description.length === 0 ? 0 : description
  }/from/${startDate}/projects/${selectedProjectId}/to/${endDate}`;
};

//erstelle jira url, wenn description aus WWW-000 besteht
export const createJiraUrl = (description: string): string | undefined => {
  const jiraUrl = "https://lumind-solutions.atlassian.net/browse/";
  const splittedDescription = description.split(":");

  if (splittedDescription.length > 1) {
    const jiraTaskShortName = splittedDescription[0];

    return jiraUrl + jiraTaskShortName;
  }
  return undefined;
};
