import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  close: () => void;
}

export const AlertDialog = ({ open, close }: Props) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="alert-dialog-title">{"Info"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deine Stundensätze wurden überschrieben.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
