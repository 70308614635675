import { ReportRow, Section, UsersDuration } from "../types";

export const totalTimeOfCombinedTimeEntry = (
  usersTime: UsersDuration[]
): number => {
  let sum = 0;
  usersTime.forEach((userTime: UsersDuration) => (sum += userTime.dur));
  return sum;
};

export const totalTimeOfARow = (rows: ReportRow[]): number => {
  let totalTime = 0;
  rows.forEach((row) => {
    row.usersTime.forEach((userTime: UsersDuration) => {
      totalTime += userTime.dur;
    });
  });
  return totalTime;
};

export const totalTimeAndTotalDiscountOfSections = (
  sections: Section[]
): number[] => {
  let totalTime = 0;
  let totalDiscount = 0;

  sections.forEach((section) => {
    totalTime += section.totalTime;
    section.rowsData.forEach((row) => {
      totalDiscount += row.discountTime;
    });
  });
  return [totalTime, totalDiscount];
};
