import TableCell from "@mui/material/TableCell";
import {
  convertMillisecondsToDecimal,
  convertMillisecondsToString,
} from "../../../utils";
import TableRow from "@mui/material/TableRow";

interface Props {
  label: string;
  totalTime: number;
  bgColor?: string;
  className?: string;
  textColor?: string;
  size?: "small" | "medium";
  isCustomerViewChecked: boolean;
}

export const TotalResultRow = ({
  label,
  className,
  totalTime,
  bgColor = "none",
  isCustomerViewChecked,
  textColor = "#000000",
  size = "small",
}: Props) => {
  const cellStyling = {
    backgroundColor: `${bgColor}`,
    color: `${textColor}`,
  };

  return (
    <TableRow>
      <TableCell className={className} style={cellStyling} size={size}>
        {label}
      </TableCell>
      <TableCell
        className={className}
        style={cellStyling}
        align={"right"}
        size={size}
      >
        {/*//Decimal View*/}
        {!isCustomerViewChecked
          ? `(${convertMillisecondsToDecimal(totalTime)}) `
          : ""}
        {convertMillisecondsToString(totalTime)}
      </TableCell>
    </TableRow>
  );
};
